import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientMembers(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/member", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientMember(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client/member", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientLoans(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/loan", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientLoan(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/loan", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
