<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">
            <feather-icon size="18" icon="UserIcon" />
            客戶
          </h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="搜尋客戶"
            />
          </div>
          
        </div>
      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="fetchClients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="未找到匹配的記錄"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: create time -->
        <template #cell(created_at)="data">
          <div class="d-flex" style="width: 160px">
            {{ data.item.created_at && getCorrectDateTime(data.item.created_at) }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
            :to="{ name: 'clients-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳細資料</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="ability.can('update', 'client') || true"
              :to="{ name: 'clients-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改資料</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至 {{ dataMeta.to }}個記錄</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useClientsList from "./useClientsList";
import clientStoreModule from "../clientStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    triggerResetPasswordModal(item) {
      this.clientInfo.id = item.id;
      this.clientInfo.name = item.name;
      this.clientInfo.password = "";
      this.clientInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-client-password");
    },
  },
  data() {
    return {
      clientInfo: { id: 0, password: "", password_confirmation: "" },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-client";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
    } = useClientsList();

    return {
      // Sidebar
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}


  .search-primary{
    width: 100%;
  }


</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    